import { PageContainer } from "ui";
import SEO from "components/SEO";

import HomeSplitBanner from "views/pages/index/HomeSplitBanner";
import InformationBlock from "views/pages/sign-up/InformationBlock";

const Home = () => (
  <>
    <SEO title={`Welcome | ${process.env.siteTitle}`} />
    <HomeSplitBanner />
    <PageContainer alignItems="center" spacing={{ base: 50, lg: 150 }}>
      <InformationBlock />
      {/* <SignUpForm /> */}
    </PageContainer>
  </>
);

export default Home;
